import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import East from '@mui/icons-material/East';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';
import { useSnackbar } from '../store';
import { SnackBar } from '../components/snackbar';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';
import Reminder from '../components/reminder';
import SignInForm from '../components/sign-in-form';
import logAnalyticsEvent from '../utils/log-analytics-event';

const StyledLink = styled('a')(() => ({
  marginBottom: 1,
  fontSize: '14px',
}));

const HomePage = ({ data }) => {
  useEffect(() => {
    const utmSource = new URLSearchParams(window.location.search).get(
      'utm_source'
    );
    logAnalyticsEvent('home_page_visit', {
      caseName: data.template.caseName,
      utmSource: utmSource,
      caseId: data.template.caseID,
    });
  }, []);

  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const isMdUp = useMediaQuery(
    () => {
      return theme.breakpoints.up('sm');
    },
    {
      defaultMatches: true,
      noSsr: false,
    }
  );

  const {
    contactInformation,
    description,
    disclaimer,
    documents,
    headline,
    importantDates,
    legalActions,
    updateNotice,
  } = data.template.home;

  const {
    idFieldName = 'id',
    loginForm = false,
    secretFieldName = 'password',
  } = data.template.authentication;

  const { instructions, caseID } = data.template;

  const documentsToShow = documents.filter((doc, index) => index < 10);

  const thereAreInstructions =
    Array.isArray(instructions) && instructions.length > 0;

  return (
    <Layout>
      <SnackBar>
        <Box sx={{ mb: 4 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '56px',
              lineHeight: '56px',
              letterSpacing: '-2px',
            }}
          >
            {data.template.caseName}
          </Typography>
          <Typography
            color="text.disabled"
            sx={{ fontWeight: 400, fontSize: { xs: '14px', md: '26px' } }}
          >
            {data.template.caseNumber} | {data.template.stateId}
          </Typography>
        </Box>
        <Grid container spacing={7}>
          <Grid item xs={12} md={8}>
            {/* {loginForm && !isMdUp && (
              <SignInForm
                idFieldName={idFieldName}
                secretFieldName={secretFieldName}
                caseId={caseID}
                openSnackbar={openSnackbar}
                navigateTo={
                  thereAreInstructions ? '/claim-instructions' : '/claim-form'
                }
              />
            )} */}
            <Grid container spacing={4}>
              {updateNotice && (
                <Grid sx={{ marginTop: !isMdUp ? 3 : 0 }} item xs={12}>
                  <Reminder title="Update:">
                    <Markdown source={updateNotice} />
                  </Reminder>
                </Grid>
              )}
              {headline && (
                <Grid item xs={12}>
                  <Box>
                    <Markdown
                      source={headline}
                      style={{
                        fontSize: '32px',
                        lineHeight: '100%',
                        whiteSpace: 'pre-wrap',
                        background: 'transparent',
                        color: '#221F20',
                      }}
                    />
                  </Box>
                </Grid>
              )}
              {/* {!loginForm && (
                <Grid item xs={12}>
                  <Button
                    LinkComponent={Link}
                    to={
                      thereAreInstructions
                        ? '/claim-instructions'
                        : '/claim-form'
                    }
                    variant="contained"
                    sx={{
                      my: 1,
                      color: 'white',
                      display: { xs: 'flex', sm: 'inline' },
                      px: { sm: '44px' },
                    }}
                    disableElevation
                  >
                    Submit a Claim
                  </Button>
                </Grid>
              )} */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {description && (
                <Grid item xs={12}>
                  <Box>
                    <Markdown
                      source={description}
                      style={{
                        fontSize: '16px',
                        whiteSpace: 'pre-wrap',
                        background: 'transparent',
                        color: '#221F20',
                      }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {legalActions && (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 700, fontSize: '28px' }}>
                      Legal Rights & Options
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {legalActions.map((action) => (
                      <Grid key={action.title} container sx={{ mb: '32px' }}>
                        <Grid item xs={12} md={3}>
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              paddingBottom: '32px',
                              mb: { xs: '32px', md: 'none' },
                            }}
                          >
                            {action.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider orientation="vertical" sx={{ mr: '32px' }} />
                        </Grid>
                        <Grid item xs={8}>
                          <Box sx={{ fontSize: '16px', fontWeight: 400 }}>
                            <Markdown
                              source={action.description}
                              style={{
                                fontSize: '16px',
                                whiteSpace: 'pre-wrap',
                                background: 'transparent',
                                color: '#221F20',
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {disclaimer && (
                <Grid item xs={12}>
                  <Box sx={{ whiteSpace: 'pre-line', fontSize: '16px' }}>
                    <Markdown
                      style={{
                        fontSize: '16px',
                        whiteSpace: 'pre-wrap',
                        background: 'transparent',
                        color: '#221F20',
                      }}
                      source={disclaimer}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              {/* {loginForm && isMdUp && (
                <SignInForm
                  idFieldName={idFieldName}
                  secretFieldName={secretFieldName}
                  caseId={caseID}
                  openSnackbar={openSnackbar}
                  navigateTo={
                    thereAreInstructions ? '/claim-instructions' : '/claim-form'
                  }
                />
              )} */}
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    border: '1px solid #D6D5D3',
                    p: '24px',
                    backgroundColor: '#fcfaf8',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '24px',
                      lineHeight: '24px',
                      letterSpacing: '0px',
                    }}
                  >
                    Frequently Asked Questions
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '16px',
                      mb: 1,
                      fontSize: '14px',
                    }}
                  >
                    For detailed information about eligibility requirements and
                    how to file a claim, visit our FAQ page.
                  </Typography>
                  <Button
                    LinkComponent={Link}
                    to="/faqs"
                    endIcon={<ArrowForwardIosIcon />}
                    sx={{
                      px: 0,
                      paddingBottom: '0px',
                      ':hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    Learn More
                  </Button>
                </Paper>
              </Grid>
              {documents && (
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{ py: '32px', px: '24px', backgroundColor: '#141516' }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '32px',
                        paddingBottom: '32px',
                        lineHeight: 1,
                        mb: 1,
                      }}
                      color="white"
                    >
                      Important Documents
                    </Typography>
                    {documentsToShow.map((doc) => (
                      <a
                        key={doc.name}
                        href={doc.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button
                          endIcon={
                            <ArrowForwardIosIcon sx={{ color: '#3E8682' }} />
                          }
                          sx={{
                            backgroundColor: '#1D1E1F',
                            width: '100%',
                            mb: 1,
                            py: 2,
                            fontWeight: 500,
                            px: 3,
                            lineHeight: 1.25,
                            justifyContent: 'space-between',
                            textAlign: 'start',
                            color: 'white',
                          }}
                        >
                          {doc.name}
                        </Button>
                      </a>
                    ))}
                    {documents.length > 10 ? (
                      <Button
                        LinkComponent={Link}
                        to="/important-documents"
                        endIcon={<East sx={{ color: '#3E8682' }} />}
                        sx={{
                          color: '#3E8682',
                          px: 0,
                          paddingBottom: '0px',
                          ':hover': { backgroundColor: 'transparent' },
                        }}
                      >
                        View all important documents
                      </Button>
                    ) : (
                      ''
                    )}
                  </Paper>
                </Grid>
              )}
              {importantDates && (
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{ backgroundColor: '#F2F0EE', px: '24px', py: '32px' }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '32px',
                        paddingBottom: '32px',
                        lineHeight: 1,
                        mb: 1,
                      }}
                    >
                      Important Dates
                    </Typography>
                    {importantDates.map((date) => {
                      const today = Date.now();
                      const deadlineDate = new Date(date.deadline).getTime();
                      const isExpired = today > deadlineDate;
                      return (
                        <Paper
                          key={date.detail}
                          elevation={0}
                          sx={{ px: '24px', py: '16px' }}
                        >
                          {isExpired && (
                            <Typography
                              sx={{
                                color: 'red',
                                fontWeight: 700,
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                              }}
                            >
                              <InfoOutlinedIcon
                                sx={{ fontSize: '18px', mr: 1 }}
                              />
                              Deadline Expired
                            </Typography>
                          )}
                          <Typography
                            sx={{
                              color: isExpired ? 'black' : '#3E8682',
                              fontWeight: 700,
                              fontSize: '14px',
                            }}
                          >
                            {new Intl.DateTimeFormat('en-US').format(
                              deadlineDate
                            )}
                          </Typography>
                          <Typography>{date.detail}</Typography>
                        </Paper>
                      );
                    })}
                  </Paper>
                </Grid>
              )}
              {!isEmpty(contactInformation) && (
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      border: '1px solid #D6D5D3',
                      p: '24px',
                      backgroundColor: '#fcfaf8',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '24px',
                        lineHeight: '24px',
                        letterSpacing: '0px',
                        paddingBottom: '16px',
                      }}
                    >
                      Questions
                    </Typography>
                    <StyledLink href={`mailto:${contactInformation.email}`}>
                      {contactInformation.email}
                    </StyledLink>
                    <Divider sx={{ my: 2 }} />
                    <a
                      href={`tel:${contactInformation.phone}`}
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {contactInformation.phone}
                    </a>
                    <Divider sx={{ my: 2 }} />
                    <Typography
                      sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                    >
                      Claim Administrator
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                      }}
                    >
                      {contactInformation.claimAdmin}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                      }}
                    >
                      {contactInformation.street}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                      }}
                    >
                      {`${contactInformation.city}, ${contactInformation.state} ${contactInformation.zipCode}`}
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SnackBar>
    </Layout>
  );
};

export default HomePage;

export const Head = ({ data }) => <title>{data.template.caseName}</title>;

export const query = graphql`
  query HomePage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseName
      caseNumber
      caseID
      stateId
      authentication {
        idFieldName
        loginForm
        secretFieldName
      }
      instructions {
        text
      }
      home {
        description
        disclaimer
        contactInformation {
          city
          claimAdmin
          email
          phone
          state
          street
          zipCode
        }
        documents {
          id
          name
          url
        }
        headline
        importantDates {
          deadline
          detail
          id
        }
        legalActions {
          description
          id
          title
        }
        privacyPolicyUrl
        updateNotice
      }
    }
  }
`;
